
	import { Component, Vue, Prop } from "vue-property-decorator";
	import { Button, Form, FormItem, Input, Modal, Upload } from "view-design";
	import { CreateElement, VNode } from "vue";

	@Component({
		components: {
			Input,
			Form,
			FormItem,
			Upload,
			Button
		}
	})
		export default class Normal extends Vue {
            @Prop() readonly paramId:any;
		    describe:string = "";

		handleExceeded () {
			this.$Notice.error({ desc: "文件大小超出限制" });
		}

		previewFile (file:any) {
			let url = file.response.data.fileurl;
			if (!file.response.data.image) {
				window.open(url);
			} else {
				const config = {
					render (h:CreateElement):VNode {
						return h("img", { attrs: { src: url }, staticStyle: { width: "100%" } });
					},
					width: 800
				};
				// @ts-ignore
				Modal.info(config);
			}
		}

		getInfo (oid:string):any {
			return {
				opt: "dealevent",
				describe: this.describe,
				// eid: this.$route.params.id,
                eid: this.paramId,
				oid,
				fileids: (this.$refs.upload as any).fileList.map((t:any) => t.response.data.docId)
			};
		}
	}
